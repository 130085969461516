import React, {useState, useEffect, lazy, Suspense} from "react"
import {useParams} from "react-router-dom"

import {Helmet} from "react-helmet";

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import { capitalizeAllWords } from "../../utils/stringUtils";
import { shortenReference } from "../../utils/interfaceUtils";
import { retryLazy } from "../../utils/applicationUtils";

import Loading from "../../components/Loading";
import ErrorBoundary from "../../components/ErrorBoundary";
// Wrap your lazy loading with retry logic
const PaymentReceipt = lazy(() =>
  retryLazy(() => import("../../containers/PaymentReceipt"))
);

const Receipt = ({receipts, payees, actions}) => {
    const params = useParams()
    const {receiptId, payeeHandleId} = params
    const [receiptLoaded, setReceiptLoaded] = useState(false)
    //load to the receipt anytime the receiptId changes
    const loadReceipt = async () => {
        actions.toggleLoading(true)
        const success = await actions.fetchSaveReceipt(receiptId)
        actions.toggleLoading(false)
        setReceiptLoaded(Boolean(success))
    }
    useEffect(() => {
        if (receiptId) loadReceipt()
    }, [receiptId])
    const receipt = receipts.receiptsById[receiptId]
    //listen to payee indicated by the payeeHandleId from the url params
    const [payeeListener, setPayeeListener] = useState(()=>()=>{})
    //track loading of payee to know if payee does not exist or is just not yet loaded
    const [payeeLoaded, setPayeeLoaded] = useState(null)
    //listen to they payee anytime the payee handle id changes
    const listenToPayee = async () => {
        const newPayeeListener = await actions.fetchSubscribeToMerchantPayeeByHandleId(payeeHandleId, () => setPayeeLoaded(true))
        setPayeeListener(() => newPayeeListener)
    }
    useEffect(() => {
        if (payeeHandleId) listenToPayee()
        return () => {
            if (typeof payeeListener === "function") payeeListener()
        }
    }, [payeeHandleId])
    if (!receipt) return ""
    const payee = payees.payeesById[payees.payeeIdByHandleId[payeeHandleId]]
    if (!payee) return ""
    const {totalXcd, subTotalXcd, senderFeeXcd, createdAt, reason, reference, identifier, lineItemsById={}} = receipt
    return (
        <div>
            <Helmet>
                <title>Receipt from {payee.name} on Shopdm Pay </title>
            </Helmet>
            <div className="margin-auto width-fit-content">
                <ErrorBoundary
                    errorHeading="Sorry, we could not load your receipt. Check your internet connection and retry"
                    errorContext="PaymentReceipt Lazy-Loading"
                >
                <Suspense fallback={<Loading />}>
                    <PaymentReceipt 
                        receiptId={receiptId}
                        payeeName={capitalizeAllWords(payee.name)}
                        payeeLogo={payee.logoImageUrlMed}
                        payeeLocation={payee.locationsById[payee.defaultLocationId]}
                        payeeEmail={payee.contactEmail}
                        payeePhone={payee.defaultContactNumber}
                        payeeId={payee.id}
                        payeeHandleId={payeeHandleId}
                        subTotalXcd={subTotalXcd}
                        totalXcd={totalXcd}
                        senderFeeXcd={senderFeeXcd}
                        paidAt={createdAt}
                        reason={reason}
                        identifier={identifier}
                        lineItemsById={lineItemsById}
                        reference={shortenReference(reference)}
                    />
                </Suspense>
                </ErrorBoundary>
            </div>
            
        </div>
    )
}

const mapStateToProps = state => ({
    receipts: state.receipts,
    payees: state.payees
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Receipt)