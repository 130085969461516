export const retryLazy = (fn, retries = 3, delay = 1000) => {
    let retriesLeft = retries;
    return new Promise((resolve, reject) => {
      const attempt = () => {
        fn()
          .then(resolve)
          .catch(err => {
            if (retriesLeft === 0) {
              reject(err);
            } else {
              retriesLeft -= 1;
              setTimeout(attempt, delay);
            }
          });
      };
      attempt();
    });
  };