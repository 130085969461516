import React from "react"
import styles from "./Main.module.css"

import {Helmet} from "react-helmet";

import PayerReceiptDateList from "../../containers/PayerReceiptDateList";

import {connect} from "react-redux"
import {Link} from "react-router-dom"

const ShopdmPayHome = "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/paydm%2Fmain%2Fhome-phone.png?alt=media&token=27eeff96-afe4-490a-a2a3-a4840eaa9c94"
const ImanuScan = "https://firebasestorage.googleapis.com/v0/b/shop-dm.appspot.com/o/paydm%2Fmain%2Fimanu-scan.jpeg?alt=media&token=a442c596-8f5a-4fea-b55d-1ab8a5078e99"
const Main = ({receipts}) => {
    return (
        <div className={styles.container}>
            <Helmet>
                <title>Shopdm Pay</title>
            </Helmet>
            <section >
                <div className={styles.heroSection}>
                    {/* picture of Roseau with green overlay */}
                    <div  className={styles.heroBackground}></div>
                    {/* centered column with phone and hero text */}
                    <div className="columnLayout">
                        <div className={styles.heroColumn}>
                            <div>
                                <div className={styles.heroText}>accept card payments worldwide from Dominica</div>
                                <div className={`h2 ${styles.subText}`}>Share a link. Get paid.</div>
                                <div className="margin-top-05em">
                                    <Link target="_blank" to="https://docs.google.com/forms/d/e/1FAIpQLSfb48bE5w_zfE62x9GjbnYif7oyQfZGUOiCy5SWDQCNwbSUow/viewform?usp=sf_link">
                                        <button className="button secondary">
                                            Interested? Sign Up Here
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <img 
                                    className={styles.homePhone} 
                                    src={ShopdmPayHome}
                                    alt="Shopdm Pay Home Screen"
                                    width="286px"
                                    height="600px"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className={`section min-height-100vh`}>
                <div className="columnLayout display-flex">
                    <div className="flex-1">
                        <div className="h1">Accept card payment in person</div>
                    </div>
                    <div className="flex-1"><img className={styles.imanuScan} src={ImanuScan} width="100%" height="auto"/></div>
                    
                </div>
            </section> */}
            {
                // Object.keys(receipts.receiptsById).length > 0 ?
                false ? //hiding due to bad user feedback, TODO revisit
                    <section className={`section ${styles.activitySection}`}>
                        <div className={`${styles.receiptList}`}>
                            <div className={`card`}>
                                <PayerReceiptDateList 
                                    title="Your Recent Receipts"
                                />
                            </div>
                        </div>
                    </section>
                :
                ""
            }
        </div>
    )
}

const mapStateToProps = state => ({
    receipts: state.receipts
})

export default connect(mapStateToProps)(Main)